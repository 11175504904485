import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getSSLCertificateColumns } from '@/constants';
import { CdnConfigurationTab } from '@/enums';
import { useAppDispatch } from '@/hooks';
import { ActionHandlers, ConfigurationCard, DraggableItem, DraggableTable } from '@components';
import { updatePageChanges, useConfigurationList, useConfigurationLoading, useConfigurationPagination } from '@store';

interface SSLFormProps {
  actionHandlers: ActionHandlers<DraggableItem>;
}

export const SSLForm = ({ actionHandlers }: SSLFormProps) => {
  const tableKey = CdnConfigurationTab.SSL;
  const dispatch = useAppDispatch();

  const { t: tTabs } = useTranslation('configuration', { keyPrefix: 'tabs.ssl' });
  const { t: tButtons } = useTranslation('configuration', { keyPrefix: 'buttons.ssl' });
  const { t: tTables } = useTranslation('description', { keyPrefix: 'configuration.tables' });

  const sslCertificateList = useConfigurationList(tableKey);

  const isSSLLoading = useConfigurationLoading(tableKey);
  const sslPagination = useConfigurationPagination(tableKey);

  const sslCertificateTableColumns = useMemo(getSSLCertificateColumns, [tTables]);

  const handlePageChange = (page) => {
    dispatch(updatePageChanges({ tableKey, page }));
  };
  return (
    <ConfigurationCard
      title={tTabs('certificateList')}
      ActionButtonProps={{
        actionHandlers: actionHandlers,
        loading: isSSLLoading,
        createLabel: tButtons('addNewCertificate'),
      }}
    >
      <DraggableTable
        rows={sslCertificateList}
        columns={sslCertificateTableColumns}
        isDataLoading={isSSLLoading}
        isDraggable={false}
        actionHandlers={actionHandlers}
        tableKey={tableKey}
        onChangePage={handlePageChange}
        pagination={sslPagination}
      />
    </ConfigurationCard>
  );
};
